'use strict';

$(document).ready(function() {
  $('.programm__video-link').magnificPopup({
    disableOn: true,
    type: 'iframe',
    mainClass: 'mfp-fade',
    removalDelay: 160,
    preloader: false,
    fixedContentPos: false
  });

  $('.header__nav-toggle').click(function(){
    $('.header__nav').toggleClass('header__nav--open');
    $('.header').toggleClass('header--open');
  });

  if($(window).width() < 768) { 
    $('.equipment__list, .team__members, .equipment__items-left').slick({
      dots: false,
      arrows: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      fade: true
    });
  }

  $(window).on('scroll', function (){
    var stopScroll = $('#info').position().top - 450;
    
    if ($(this).scrollTop() > 1000 && $(this).scrollTop() < stopScroll){
      $('.promo__anchor-link').addClass('promo__anchor-link--fixed');
    } else {
      $('.promo__anchor-link').removeClass('promo__anchor-link--fixed');
    }
  });
});